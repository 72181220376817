body {
  background-color: #f3f3f3;
  font-family: 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 12px;
  -webkit-touch-callout: auto; /* iOS Safari */
  -webkit-user-select: auto; /* Safari */
  -khtml-user-select: auto; /* Konqueror HTML */
  -moz-user-select: auto; /* Firefox */
  -ms-user-select: auto; /* Internet Explorer/Edge */
  user-select: auto; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

tr {
  cursor: pointer;
}

#app {
  height: 100%;
}

/**********************************
* Variables Project
***********************************/
:root {
  --font-color: #646464;
  --btn-color-primary: #295896;
  --border-color-wrapper: #f2f2f2;
  --border-color-default: #d9d9d9;
  --color-placeholder: #9b9b9b;
  --color-title-module: #233539;
  --color-green: #23a71e;
  --color-green-two: #7ed320;
  --color-red: #b3373f;
  --color-white: #ffffff;
  --color-background: #fbfbfb;
}

/**********************************
* Container With Pages Components
***********************************/
.Wrapper {
  margin: 0 auto !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  background-color: #ffffff;
}

.Wrapper_Size_1 {
  margin: 0 auto !important;
  width: 1024px !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  background-color: #ffffff;
}

.Wrapper__Row {
  width: 100%;
  margin: 0 auto !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.Wrapper__Body {
  width: 1024;
}

.Body__Container {
  margin: 0 auto;
  padding: 10px;
  width: 100%;
}

/**********************************
* Sidebar component
***********************************/

.Wrapper__SideBar {
  background-color: #5f39de;
  color: white;
  font-size: 12px;
  width: 12%;
  height: 100%;
  float: left;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.Wrapper__SideBar_Overlay {
  background-color: #5f39de;
  color: white;
  font-size: 12px;
  position: absolute;
  width: 100px;
  left: 0;
  top: 0;
  z-index: 1;
  animation: 0.3s ease-out 0s 1 slideInFromLeft;

  -webkit-box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.1);
}

.Wrapper__SideBar_Overlay_Hide {
  background-color: #5f39de;
  color: white;
  font-size: 12px;
  position: absolute;
  width: 100px;
  left: 0;
  top: 0;
  z-index: 1;
  animation: 0.3s ease-out 0s 1 slideInFromRight;

  -webkit-box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.1);
}

.Sidebar__Link {
  color: white;
  width: 100%;
  border: none;
}

.Sidebar__Link:hover {
  color: white;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.Sidebar__Menu__Item {
  border: none;
  margin-top: 8px;
  margin-bottom: 8px;
}

.Logo {
  margin-top: 30px;
  text-align: center;
}

.Sidebar__Menu__Item:hover {
  border: none;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: rgba(0, 0, 0, 0.3);
}

.Sidebar__Logo__Container {
  height: 135px;
  text-align: center;
}

.ui.selection.list > .item:hover {
  background: none;
  color: rgba(0, 0, 0, 0.8);
}

.Sidebar__Logo__Container:hover {
  height: 135px;
  background-color: rgba(0, 0, 0, 0);
}

.Sidebar__Menu {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.Active {
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: 8px;
}

.List__Item {
  width: 100%;
  margin-top: 10px;
}

/**********************************
* Topbar component
***********************************/

.Topbar {
  height: 70px !important;
  width: 100%;
  background: white !important;
  border-bottom: 1px solid var(--border-color-wrapper) !important;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
}

.TopbarSmall {
  height: 70px !important;
  width: 1024px !important;
  background: white !important;
  border-bottom: 1px solid var(--border-color-wrapper) !important;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
}

.Topbar__Search {
  padding: 5px;
  border: none;
}

/**********************************
* SessionDropdown component
***********************************/

.SessionDropdown .ui.dropdown .menu {
  background-color: #333333;
  min-width: 250px;
  padding: 5px 0px;
}

.SessionDropdown .ui.dropdown .menu .header {
  color: #a6a6a6;
  font-weight: normal;
}

.SessionDropdown .ui.dropdown .menu .description,
.SessionDropdown .ui.dropdown .menu .item {
  color: #fff;
}

.SessionDropdown .ui.dropdown .menu .divider {
  border-top-color: #383838;
}

/**********************************
* Table component
***********************************/

.Table__List tr td {
  border-top: 0px !important;
  border: 0px !important;
  text-align: center;
}

/**********************************
* Modal component
***********************************/
.Modal__Form {
  margin: 0 auto;
  width: 80%;
  margin-top: 10px;
}

.Modal__Form-SubModal {
  border-radius: 20px;
  overflow-y:auto;
}

.Padded__Form {
  padding: 30px;
}

.From__Identicon {
  margin: 0 auto;
  text-align: center;
}

.Tab__Menu {
  width: 100%;
}

.ui.secondary.pointing.menu .item {
  margin: 0 auto;
  text-align: center;
}

.ui.secondary.pointing.menu {
  /*border-bottom: 2px solid #5f39de;*/
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.ui.secondary.pointing.menu .item {
  margin: -3px auto;
  margin-left: -5px;
  margin-right: initial;
  text-align: left;
}
.ui.secondary.pointing.menu .active.item {
  border-bottom: 2px solid #5f39de;
}

.ui.secondary.pointing.menu .active.item:hover {
  border-bottom: 2px solid #5f39de;
}

.Form__Subtitle {
  color: #999999;
  font-size: 10px;
}

.ui.disabled.dropdown,
.ui.dropdown .menu > .disabled.item {
  top: 0;
  opacity: 1;
}

.ui.grid {
  margin: 0 auto;
  width: 100%;
}

.Ode__Detail__Right__Menu {
  background-color: white;
  height: 100%;
}

.Right__Container {
  min-height: 95%;
}

.Ode__Detail__Container {
  margin: 10px auto;
  width: 100%;
  height: 100%;
}

.ui.column.grid > [class*="fourteen wide"].column,
.ui.grid > .column.row > [class*="fourteen wide"].column,
.ui.grid > .row > [class*="fourteen wide"].column,
.ui.grid > [class*="fourteen wide"].column {
  padding: 0;
}

.Ode__Detail__ContainerTabs {
  width: 100%;
  margin-left: 5px;
  float: left;
  border: none;
  border-radius: none;
  box-shadow: none;
  height: 100%;
}

.Ode__Detail__RightMenu {
  width: 25%;
  margin-left: 5px;
  float: right;
}

.ui.grid > .row {
  padding-top: 0;
}

.ui.segment {
  border: none;
  box-shadow: none;
}

.ui.segment.shadow {
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  border: 1px solid rgba(34,36,38,.15);
}

.ui.segment.less-padding {
  padding: 0.5em;
}

.ui.container {
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.ui.modal > .header {
  display: block;
  background: #fff;
  margin: 0;
  padding: 1.25rem 1.5rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: none;
}

.ui.feed > .event > .label .icon {
  font-size: 1em;
}

.ui.secondary.pointing.menu {
  margin-left: 0;
  margin-right: 0;
  border-bottom: none;
}

.ui.tiny.modal.active {
  max-height: 90%;
  overflow-y: auto;
}

.ui.form .field > label {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}

.ui.blue.button,
.ui.blue.buttons .button {
  background-color: #5f39de;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.ui.primary.button,
.ui.primary.buttons .button {
  background-color: #5f39de;
  color: #fff;
  text-shadow: none;
  background-image: none;
  border-radius: 10px;
}

.ui.secondary.button,
.ui.secondary.buttons .button {
  background-color: #ebe6fb;
  color: #5936cf;
  text-shadow: none;
  background-image: none;
  border-radius: 100px;
  border: 0.4px solid rgba(89, 54, 207, 0.25);
}

.ui.primary.button:hover,
.ui.primary.button:focus {
  background-color: #5f39de;
  color: #fff;
  text-shadow: none;
  background-image: none;
  border-radius: 10px;
}

.ui.secondary.button:hover,
.ui.secondary.button:focus {
  background-color: #ebe6fb;
  text-shadow: none;
  background-image: none;
  border-radius: 100px;
  color: #5936cf;
}

.ui.blue.button,
.ui.blue.buttons .button:hover {
  background-color: #5f39de;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.roundedButton {
  background: #EBE6FB;
  border: 0.4px solid rgba(89, 54, 207, 0.25);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  padding: 5px 0;
  cursor: pointer;
}

.ui.selection.list .list > .item,
.ui.selection.list > .item {
  cursor: pointer;
  background: 0 0;
  padding: 0.5em 0.5em;
  margin: 0;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 0.5em;
  -webkit-transition: 0.1s color ease, 0.1s padding-left ease,
    0.1s background-color ease;
  transition: 0.1s color ease, 0.1s padding-left ease,
    0.1s background-color ease;
}

.ui.list .list > .item .header,
.ui.list > .item .header {
  display: block;
  margin: 0;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  padding: 3px;
}

.ui.selection.list .list > .item,
.ui.selection.list > .item:hover {
  cursor: pointer;
  background: 0 0;
}

.ui.selection.list.list > .item:hover,
.ui.selection.list > .item:hover {
  background: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.8);
}

/*.ui.checkbox input:focus:checked ~ .box:before,
.ui.checkbox input:focus:checked ~ label:before {
  background-color: #5f39de !important;
  color:#ffffff !important;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  background-color: #ffffff;
}
*/

.ui.tiny.modal.active {
  max-height: 90%;
  overflow-y: auto;
  border-radius: 18px;
}

.ui.small.modal.active {
  max-height: 90%;
  overflow-y: auto;
  border-radius: 18px;
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
  width: auto;
  /* Selectable */

  background: #f7f9fc;
  /* Windows border */

  border: 1px solid #e9eaeb;
  border-radius: 4px;
}

.ui.form input[type="checkbox"],
.ui.form textarea {
  vertical-align: top;
  background: #f7f9fc;
  /* Windows border */

  border: 1px solid #e9eaeb;
  border-radius: 4px;
}

.ui.form .field > .selection.dropdown {
  width: 100%;
  background: #f7f9fc;
  /* Windows border */

  border: 1px solid #e9eaeb;
  border-radius: 4px;
}

.ui.table {
  position: absolute;
  top: -15px;
  left: -1px;
}

.ui.table td {
  padding: 0.28571429em 0.28571429em;
  text-align: inherit;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.0923077px;
}

.ui.basic.striped.table tbody tr:nth-child(2n) {
  background-color: #f7f9fc !important;
}

.ui.basic.striped.table tbody tr:hover {
  background-color: #ebe6fb !important;
}

.ui.basic.table th, .ui.basic.table thead th {
  background: 0 0;
  border-left: none;
  background-color: #f9fafe !important;
  border: none;
  font-size: 11px;
  line-height: 14px;
  border-top: 1px solid #dddddd;
  border-radius: none;
}

.ui.list .list > .item .header,
.ui.list > .item .header {
  display: block;
  margin: 0;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  padding: 2px;
}

.ui.selection.list .list > .item,
.ui.selection.list > .item {
  cursor: pointer;
  background: 0 0;
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 0;
  -webkit-transition: 0.1s color ease, 0.1s padding-left ease,
    0.1s background-color ease;
  transition: 0.1s color ease, 0.1s padding-left ease,
    0.1s background-color ease;
}

.ui.teal.label,
.ui.teal.labels .label {
  background-color: #00d4cf !important;
  border-color: #00d4cf !important;
  color: #fff !important;
  padding: 3px !important;
}

.ui.green.label,
.ui.green.labels .label {
  background-color: #ccffdf !important;
  border-color: #21ba45 !important;
  color: #21ba45 !important;
}

/* .ui.yellow.label,
.ui.yellow.labels .label {
  background-color: #fffccc !important;
  border-color: #d6d176 !important;
  color: #d6d176 !important;
} */

.scroll {
  width: 200px;
  height: 400px;
  overflow-y: scroll;
}
.scroll::-webkit-scrollbar {
  width: 0px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 1px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.ui.mini.modal {
  width: 360px;
  margin: 0;
  border-radius: 18px;
}

.ui.dimmer .ui.workaround.loader:before {
  border-color: rgba(0,0,0,.1);
}
          
.ui.dimmer .ui.workaround.loader:after {
  border-color: #767676 transparent transparent;
}

.no-scroll {
  overflow: hidden;
}

.scrollable {
  overflow-y: auto;
}

.full-height {
  height: 100%;
}

.ui.segments.compact.horizontal {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ui.segments.compact.horizontal.basic {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0px;
}

.ui.segments.compact.horizontal.basic .segment {
  border: 0px;
}

.ui.fluid.search > .ui.input {
  width: 100%;
}

.ui.grid.no-padding .row .column {
  padding-left: 0px;
  padding-right: 0px;
}

.ui.secondary.pointing.menu.compact {
  width: auto;
}

.row.sidebar-entity-list .column .feed .event .content {
  margin: 0 0 0 0.5em;
}

.row.sidebar-entity-list .column .feed .event .content .summary a {
  color: rgba(0,0,0,.87);
  font-size: 0.9em;
}

.ui.vertically.padded.grid .row .column {
  padding-bottom: 2rem;
}

.grid.expert-services-cards .ui.card .extra.content {
  height: 3rem;
}

.ui.celled.grid>.column:not(.row), .ui.celled.grid>.row.sidebar-list>.column {
  padding: 0em;
}

.row.sidebar-list > .column > .ui.grid .row {
  padding-bottom: 0em;
}

.row.sidebar-list > .column > .ui[class*="vertically divided"].grid>.row:before {
  width: 100%;
  margin: 0px;
}

.row.sidebar-list > .column > .ui.grid .row.selected {
  border-right: solid 5px #5f39de;
  background: #F6F8FC;
}

.row.sidebar-list > .column > .ui.grid .column {
  padding: 1em 1em 1em 2em;
  margin-bottom: 0em;
  color: #979CA3;
  margin-top: 0em;
}

.row.sidebar-list > .column > .ui.grid .ui.header {
  font-weight: 400;
  font-size: 1.3em;
  margin-bottom: 0.2em;
}

.detail-grid-section .action-items > .content > * {
  vertical-align: top;
}

.ui.segment.no-padding {
  padding: 0;
}

.detail-grid-info-row {
  font-size: 2em;
  font-size: 1rem;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.detail-grid-info-row .label {
  color: rgba(0,0,0,.6)
}

.ui.celled.grid>.row>.column.no-padding {
  padding: 0px;
}

/******************************************
* Filter Buttons
*******************************************/
div.field > button.filterButtons {
  background-color: #FFF;
  border: solid 0.5px #999;
  padding: 5px;
}

div.field > button.selectedFilterButtons {
  border-color: #5F39DE;
  color: #5F39DE;
}

/******************************************
* Details Information Tab
*******************************************/
.details-info-tab {
  padding-top: 20px;
  padding-left: 35px;
  padding-right: 35px;
}

/******************************************
* Details Info Sections
*******************************************/
.detail-section {
  margin-top: 20px;
  border-bottom: 1px solid #DDD;
}

.selectable-item:hover {
  cursor: pointer;
}

/** Entrepreneur Form **/
.ui.container.fill-background {
  background-color: #5f39de;
}

.entrepreneur-registration .heading {
  padding-bottom: 20px
}


/******************************************
* Batch Details Page
*******************************************/
.batchDetail-batchOdesList-container {
  padding-bottom: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.batchDetail-batchOdesList-container
  .list-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.batchDetail-batchOdesList-container
  .list-item-container
  .labels-container {
    display: flex;
    flex-direction: column;
  }

.batchDetail-batchOdesList-container
  .list-item-container
  .labels-container
  span {
    color: #323C47;
    opacity: 0.5;
    font-size: 10px;
  }



