@font-face {
  font-family: "fontello";
  src: url("../font/fontello.eot?91531884");
  src: url("../font/fontello.eot?91531884#iefix") format("embedded-opentype"),
    url("../font/fontello.woff2?91531884") format("woff2"),
    url("../font/fontello.woff?91531884") format("woff"),
    url("../font/fontello.ttf?91531884") format("truetype"),
    url("../font/fontello.svg?91531884#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?91531884#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-doublecheck:before {
  content: "\e800";
} /* '' */
.icon-check:before {
  content: "\e801";
} /* '' */
.icon-cancel_contained:before {
  content: "\e802";
} /* '' */
.icon-cancel:before {
  content: "\e803";
} /* '' */
.icon-link_delete:before {
  content: "\e804";
} /* '' */
.icon-message_cancel:before {
  content: "\e805";
} /* '' */
.icon-delete:before {
  content: "\e806";
} /* '' */
.icon-node_delete:before {
  content: "\e807";
} /* '' */
.icon-document_delete:before {
  content: "\e808";
} /* '' */
.icon-calendar:before {
  content: "\e809";
} /* '' */
.icon-calendar_add:before {
  content: "\e80a";
} /* '' */
.icon-calendar_delete:before {
  content: "\e80b";
} /* '' */
.icon-calendar_cancel:before {
  content: "\e80c";
} /* '' */
.icon-attach:before {
  content: "\e80d";
} /* '' */
.icon-user_add:before {
  content: "\e80e";
} /* '' */
.icon-upload:before {
  content: "\e80f";
} /* '' */
.icon-search:before {
  content: "\e810";
} /* '' */
.icon-user_find:before {
  content: "\e811";
} /* '' */
.icon-table:before {
  content: "\e812";
} /* '' */
.icon-datatables:before {
  content: "\e813";
} /* '' */
.icon-chart_pie:before {
  content: "\e814";
} /* '' */
.icon-book:before {
  content: "\e815";
} /* '' */
.icon-shelve:before {
  content: "\e816";
} /* '' */
.icon-spreadsheet:before {
  content: "\e817";
} /* '' */
.icon-plus:before {
  content: "\e818";
} /* '' */
.icon-warning:before {
  content: "\e819";
} /* '' */
.icon-camera:before {
  content: "\e81a";
} /* '' */
.icon-monitor:before {
  content: "\e81b";
} /* '' */
.icon-money:before {
  content: "\e81c";
} /* '' */
.icon-share:before {
  content: "\e81e";
} /* '' */
.icon-folder:before {
  content: "\e81f";
} /* '' */
.icon-email:before {
  content: "\e820";
} /* '' */
.icon-user:before {
  content: "\e821";
} /* '' */
.icon-facebook:before {
  content: "\e822";
} /* '' */
.icon-settings:before {
  content: "\e823";
} /* '' */
.icon-instagram:before {
  content: "\e824";
} /* '' */
.icon-whatsapp:before {
  content: "\e826";
} /* '' */
.icon-twitter:before {
  content: "\e827";
} /* '' */
.icon-graduation:before {
  content: "\e828";
} /* '' */
.icon-notification:before {
  content: "\e829";
} /* '' */
.icon-wifi:before {
  content: "\e82a";
} /* '' */
.icon-message:before {
  content: "\e82b";
} /* '' */
.icon-book_open:before {
  content: "\e82d";
} /* '' */
.icon-domain:before {
  content: "\e82e";
} /* '' */
.icon-password:before {
  content: "\e82f";
} /* '' */
.icon-laptop:before {
  content: "\e830";
} /* '' */
.icon-bullish:before {
  content: "\e831";
} /* '' */
.icon-like:before {
  content: "\e832";
} /* '' */
.icon-link:before {
  content: "\e833";
} /* '' */
.icon-commercial:before {
  content: "\e834";
} /* '' */
.icon-messenger:before {
  content: "\e835";
} /* '' */
.icon-scales:before {
  content: "\e836";
} /* '' */
.icon-diploma:before {
  content: "\e837";
} /* '' */
.icon-statistics:before {
  content: "\e838";
} /* '' */
.icon-workflow:before {
  content: "\e839";
} /* '' */
.icon-circled_left:before {
  content: "\e83a";
} /* '' */
.icon-building-1:before {
  content: "\e83b";
} /* '' */
.icon-circled_right:before {
  content: "\e83c";
} /* '' */
.icon-bookmark:before {
  content: "\e83d";
} /* '' */
.icon-graduate:before {
  content: "\e83e";
} /* '' */
.icon-email_group:before {
  content: "\e83f";
} /* '' */
.icon-admin:before {
  content: "\e840";
} /* '' */
.icon-mixer:before {
  content: "\e841";
} /* '' */
.icon-toggle:before {
  content: "\e842";
} /* '' */
.icon-directory:before {
  content: "\e843";
} /* '' */
.icon-groups:before {
  content: "\e844";
} /* '' */
.icon-group_foreground:before {
  content: "\e845";
} /* '' */
.icon-meassuring:before {
  content: "\e846";
} /* '' */
.icon-circle_fill:before {
  content: "\e847";
} /* '' */
.icon-circled_empty:before {
  content: "\e848";
} /* '' */
.icon-clock:before {
  content: "\e849";
} /* '' */
.icon-edit:before {
  content: "\e84a";
} /* '' */
.icon-more:before {
  content: "\e84b";
} /* '' */
.icon-more_container:before {
  content: "\e84c";
} /* '' */
.icon-info:before {
  content: "\e84d";
} /* '' */
.icon-phone:before {
  content: "\e84e";
} /* '' */
.icon-marker:before {
  content: "\e84f";
} /* '' */
.icon-menu:before {
  content: "\e850";
} /* '' */
.icon-hearts:before {
  content: "\e851";
} /* '' */
.icon-play:before {
  content: "\e852";
} /* '' */
.icon-idea:before {
  content: "\e853";
} /* '' */
.icon-coins:before {
  content: "\e854";
} /* '' */
.icon-eye:before {
  content: "\e855";
} /* '' */
.icon-image:before {
  content: "\e856";
} /* '' */
.icon-training:before {
  content: "\e857";
} /* '' */
.icon-marker_place:before {
  content: "\e858";
} /* '' */
.icon-business:before {
  content: "\e859";
} /* '' */
.icon-key:before {
  content: "\e85a";
} /* '' */
.icon-shopping:before {
  content: "\e85b";
} /* '' */
.icon-organization:before {
  content: "\e85c";
} /* '' */
.icon-institution:before {
  content: "\e85d";
} /* '' */
.icon-building:before {
  content: "\e85e";
} /* '' */
.icon-embassy:before {
  content: "\e85f";
} /* '' */
.icon-apartment:before {
  content: "\e860";
} /* '' */
.icon-undo:before {
  content: "\e861";
} /* '' */
.icon-mindmap:before {
  content: "\e862";
} /* '' */
.icon-map:before {
  content: "\e863";
} /* '' */
.icon-send:before {
  content: "\e864";
} /* '' */
.icon-radio:before {
  content: "\e865";
} /* '' */
.icon-chart_flow:before {
  content: "\e866";
} /* '' */
.icon-image_file:before {
  content: "\e867";
} /* '' */
.icon-agreement:before {
  content: "\e868";
} /* '' */
.icon-feed:before {
  content: "\e869";
} /* '' */
.icon-imac:before {
  content: "\e86a";
} /* '' */
.icon-chart_area:before {
  content: "\e86b";
} /* '' */
.icon-template:before {
  content: "\e86c";
} /* '' */
.icon-siren:before {
  content: "\e86d";
} /* '' */
.icon-mixer_vertical:before {
  content: "\e86e";
} /* '' */
.icon-book_opened:before {
  content: "\e86f";
} /* '' */
.icon-registry:before {
  content: "\e870";
} /* '' */
.icon-layers:before {
  content: "\e871";
} /* '' */
.icon-advertising:before {
  content: "\e872";
} /* '' */
